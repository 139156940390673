import { API, Auth } from "aws-amplify";
// Sanitize filename by removing or replacing undesired characters
export const sanitizeFileNameWithSubjectID = async ({ file, selectedSubjectId }: { file: File, selectedSubjectId: string }): Promise<{ key: string; file: File }> => {
    let sanitizedFileName = '';

    sanitizedFileName = file.name.replace(/\s+/g, '_').replace(/[<>:"/\\|?*]+/g, '');

    console.log(sanitizedFileName);
    const userID = await getUserID();
    const url = `/users/${userID}/subjects/${selectedSubjectId}/slides`
    const response = await API.post('histopathologyAPI', url, {
        body: { file_name: sanitizedFileName.split('.')[0] }
    });
    const uploadPath = `subjects/${selectedSubjectId}/slides/${response.SlideID}/${sanitizedFileName}`;
    // Explicitly return file as File, not Blob, to satisfy TypeScript's type checking
    return { file, key: uploadPath };
};


export const generateThumbnail = (filepath: string) => {
    const url = `/generatethumbnail`;
    console.log(filepath);
    Auth.currentCredentials()
      .then(credentials => {
        const identityID = credentials.identityId;
        console.log('Initiating thumbnail generation for:', filepath);
        
        API.post('histopathologyAPI', url, {
          body: {
            slidePath: filepath,
            identityID: identityID
          }
        })
          .then(() => {
            console.log('Thumbnail generation initiated successfully. This may take a while.');
            // Optionally, you can show a message to the user indicating that the process has started
          })
          .catch(error => {
            console.error('Error initiating thumbnail generation:', error);
            // Handle the error appropriately (e.g., show an error message to the user)
          });
      })
      .catch(error => {
        console.error('Error retrieving credentials:', error);
        // Handle the error appropriately (e.g., show an error message to the user)
      });
    
    console.log('Thumbnail generation process started for:', filepath);
    return filepath;
  };
  

export const getResult = async (inferenceId: string): Promise<any> => {
    try {
        const data = await API.get('histopathologyAPI', `/result/${inferenceId}`, {});
        return data;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getProcessedImage = async (inferenceId: string): Promise<any> => {
    try {
        const data = await API.get('histopathologyAPI', `/processedImage/${inferenceId}`, {});
        return data;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const classifyImage = async (res: { image_name: string; inference_id: string } | undefined): Promise<any> => {
    if (!res) return null;

    try {
        const data = await API.post('histopathologyAPI', `/classify/`, {
            body: { image_name: res.image_name, inference_id: res.inference_id }
        });
        return data;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getUserID = async (refresh: boolean = false): Promise<string> => {
    if (!refresh) {
        const cachedUserID = sessionStorage.getItem('userID');
        if (cachedUserID) {
            return cachedUserID;
        }
    }
    try {

        const session = await Auth.currentSession(); // Get the current session
        const idToken = session.getIdToken().getJwtToken(); // Get the ID Token
        const response = await API.get('histopathologyAPI', '/users/current', {
            headers: {
                Authorization: idToken // Make sure to include the ID token here
            }
        });

        const userID = response[0].id;


        sessionStorage.setItem('userID', userID);
        return userID;
    } catch (error) {
        console.error('Error fetching user ID:', error);
        throw error;
    }
};

export const postToPatientEndpoint = async (route: string, subjectId: string, jsonObject: any): Promise<any> => {
    let userID = await getUserID();
    try {
        const response = await API.post('histopathologyAPI', `/users/${userID}/subjects/${subjectId}/${route}`, { body: jsonObject });
        return response;
    } catch (error: any) {
        console.error('Error posting to endpoint:', error);
        throw error;
    }
};

export const getToPatientEndpoint = async (route: string, subjectId: string, jsonObject: any): Promise<any> => {
    let userID = await getUserID();
    try {
        const response = await API.get('histopathologyAPI', `/users/${userID}/subjects/${subjectId}/${route}`, { body: jsonObject });
        return response;
    } catch (error: any) {
        console.error('Error posting to endpoint:', error);
        throw error;
    }
};

