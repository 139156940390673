import React from "react";
import {Alert, Button, Flex, Input, Label} from "@aws-amplify/ui-react";
import {API} from "aws-amplify";
import {getUserID} from "../api/api";

type AddSubjectFormProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubjectAdded: () => void;
};

export const AddSubjectForm: React.FC<AddSubjectFormProps> = ({ isOpen, onClose, onSubjectAdded }) => {
    const [subjectIdentifier, setSubjectIdentifier] = React.useState("");
    const [age, setAge] = React.useState<number | ''>('');
    const [diagnosis, setDiagnosis] = React.useState("");

    const handleAddSubject = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const userID = await getUserID();
            await API.post("histopathologyAPI", `/users/${userID}/subjects`, {
                body: {
                    name: subjectIdentifier,
                    age: String(age),
                    diagnosis: diagnosis
                }
            });
            onSubjectAdded();
            setSubjectIdentifier("");
            setAge('');
            setDiagnosis("");
            onClose();
        } catch (error) {
            console.error("Error adding subject:", error);
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <Alert
            isDismissible={false}
            hasIcon={false}
            heading="Add a new subject"
            style={{ marginBlock: "2rem" }}
        >
            <form onSubmit={handleAddSubject}>
                <Flex direction="column" gap="small">
                    <Label htmlFor="subjectIdentifier">Subject Identifier:</Label>
                    <Input
                        id="subjectIdentifier"
                        name="subjectIdentifier"
                        value={subjectIdentifier}
                        onChange={(e) => setSubjectIdentifier(e.target.value)}
                        required
                    />
                    <Label htmlFor="age">Age (optional):</Label>
                    <Input
                        id="age"
                        name="age"
                        type="number"
                        value={age}
                        onChange={(e) => setAge(Number(e.target.value))}
                    />
                    <Label htmlFor="diagnosis">Diagnosis (optional):</Label>
                    <Input
                        id="diagnosis"
                        name="diagnosis"
                        value={diagnosis}
                        onChange={(e) => setDiagnosis(e.target.value)}
                    />
                    <Flex direction="row" justifyContent="space-between">
                        <Button type="submit" variation="primary">Add Subject</Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </Flex>
                </Flex>
            </form>
        </Alert>
    );
};
