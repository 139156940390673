import { Flex, Image, Heading, Text, Button, Divider } from '@aws-amplify/ui-react';
import './Vision.css';
import { TiSocialLinkedin } from 'react-icons/ti';
import { MdEmail } from 'react-icons/md';
import WhiteCard from "../../components/WhiteCard";
export default function Vision() {
    return (
        <>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'center',
                alignItems: 'center',
                //height: '100vh',
                //textAlign: 'center'
            }}>
                <div>
                    <h2>Vision</h2>
                </div>
                <WhiteCard>

                    <Flex className="parent-container">
                        <Image src={`${process.env.PUBLIC_URL}/OctopathLogo.svg`} alt="Team Logo" className="logo" />
                    </Flex>
                    <Heading level={5} className='header-statement' >The pathology crisis</Heading>
                    <Text className="vision-statement">
                        Treatment of cancer started to change because of the detection of targetable genetic alterations about 20 years ago; the exemplar being imatinib for treating leukaemia (CLL) and gastrointestinal stromal tumours (GIST). With advances in genomic sequencing and immuno-oncology, new treatments targeting specific biological functions are rapidly being developed. However, these advances have shown that each cancer type, e.g., breast cancer, has multiple subtypes associated with different clinical outcomes and responses to therapies. The advances allow patients to receive treatments tailored to their specific cancer subtype.  Still, classification of cancer has become more complicated over the last two decades and continues to do so. The clinician must request relevant ancillary tests which need to be delivered in a timely manner, prolonging the diagnostic pathway. Furthermore, more specialist knowledge is required by clinicians to guide subsequent treatment. The increasing workload is being delivered by a shrinking workforce and a crisis point is being reached. There already is a 25% shortfall of staff, which is set to decline, able to report results. In some regions the shortage is even greater. A solution which can sustain diagnostic services is required to support the diagnostic pathway.
                    </Text>
                    <Heading level={5} className='header-statement' >AI as a solution</Heading>
                    <Text className="vision-statement">
                        AI may be able to ensure that delivery of tissue diagnoses is sustainable and supports personalised treatments. The development of AI for tissue diagnoses is timely as full adoption of digital pathology images, allowing them to be interrogated by both humans and artificial intelligence (AI), is expected in the UK by 2025. The benefit of AI in this context includes diagnostic efficiency: AI algorithms, particularly deep learning models, have demonstrated strong capability in identifying patterns in medical images, such as digital pathology slides. These algorithms can process large datasets rapidly, potentially reducing the time required for diagnosis and providing an educated to the pathologists that may reduce the required number of ancillary tests. This efficiency is critical for prompt treatment initiation, impacting patient outcomes. Besides, by handling routine tasks such as mitoses count and tumour delineation, and highlighting cases that require expert review, AI can reduce the burden on pathologists, allowing them to concentrate on more complex diagnostic challenges and patient consultation. Finally, AI's ability to analyse diverse data types, including genomic information and pathology images, enables the identification of effective treatment options tailored to the genetic profile of individual patients' cancers.
                    </Text>
                    <Heading level={5} className='header-statement' >Octopath's role in supporting the pathologist</Heading>
                    <Text className="vision-statement">
                        We have developed and are currently in the prototype and deployment stage of specific mitoses detection models, lymphocytes detection models, and we built an AI model to help clinician diagnoses several sarcoma sub-types. Our models use state-of-the-art image classification model strengthened by known tumour identifying markers (nerve sheath, smooth and skeletal muscle, fat, cell and nuclear size, chromatin density etc as well as cellular pleomorphism, inflammatory infiltrate, vascular structures, mitotic activity, and presence of tumour necrosis) to provide routine clinical information and a diagnosis on a digitized specimen.

                    </Text>

                    <Divider orientation="horizontal" className="divider" />
                    <Flex direction="row" justifyContent="center" gap="1rem">
                        <Button variation="primary" className="contact-button">Learn More</Button>
                        <Flex direction="row" alignItems="center" className="social-links">
                            <TiSocialLinkedin size={30} />
                            <MdEmail size={30} />
                        </Flex>
                    </Flex>

                </WhiteCard >
            </div>

        </>

    );
}
