import React from "react";
import { useNavigate } from 'react-router-dom';
import { Link, Menu, MenuButton, MenuItem, useAuthenticator, View} from "@aws-amplify/ui-react";
import { MdAccountCircle } from "react-icons/md";
import { BsGithub } from "react-icons/bs";
type HeaderProps = {
    loggedIn: boolean;
};

const HeaderNav: React.FC<HeaderProps> = ({ loggedIn }) => {
    const navigate = useNavigate();
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const handleSignOut = async () => {
        await signOut();
        navigate('/');

    }
    const goToClassificationRepo = () => {
        window.location.href = 'https://github.com/cacof1/DigitalPathologyAI';
    };

    const goToCellDetectionRepo = () => {
        window.location.href = 'https://github.com/cacof1/DigitalPathologyAI';
    };

    const gotoDashboard = () => {
        window.location.href = '/dashboard';
    };

    return (
        <>

            <Menu
                menuAlign="end"
                trigger={
                    <MenuButton variation="menu">
                        <div className="github-avatar">
                            <BsGithub  />
                        </div>
                    </MenuButton>
                }

            >
                <MenuItem onClick={goToClassificationRepo}>Classification</MenuItem>
                <MenuItem onClick={goToCellDetectionRepo}>Cell Detection</MenuItem>
            </Menu>
            {loggedIn && <Menu
                menuAlign="end"
                trigger={
                    <MenuButton variation="menu">
                        <div className="header-avatar">
                            <MdAccountCircle />
                        </div>
                    </MenuButton>
                }
            >

                <MenuItem onClick={handleSignOut}>Logout</MenuItem>
                <MenuItem onClick={gotoDashboard}>Dashboard</MenuItem>
            </Menu>}
            {!loggedIn && <View padding="1rem">
                <Link href="/dashboard">Login</Link>
            </View>}
        </>
    );
};

export default HeaderNav;
