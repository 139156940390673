import { useEffect, useState } from "react"
import { Flex, SelectField } from "@aws-amplify/ui-react";
import WhiteCard from "../../components/WhiteCard";
import { Storage} from "aws-amplify";
import ViewerComp from "../../feature/ViewerComp";
import InferencesSummaryTable from "../../feature/InferencesSummaryTable";
export default function Demo() {
    const [slideURL, setSlideURL] = useState<string>("");
    const [selectedSlide, setSelectedSlide] = useState('');
    const [selectedSlideID, setSelectedSlideID] = useState('');
    const [selectedSlideFolder, setSelectedSlideFolder] = useState('');
    const [visibleMap, setVisibleMap] = useState<string | null>(null);
    const slides = [
        {
            id: '30',
            label: 'Soft-Tissue Sarcoma -1, Site: Connective, subcutaneous and other soft tissues',
            value: 'TCGA-DX-AATS-01Z-00-DX2.463742C8-7526-4D1A-9508-0E18EAB56FF4/TCGA-DX-AATS-01Z-00-DX2.463742C8-7526-4D1A-9508-0E18EAB56FF4.svs',
            folder: 'TCGA-DX-AATS-01Z-00-DX2.463742C8-7526-4D1A-9508-0E18EAB56FF4'
        },
        {
            id: '31',
            label: 'Soft-Tissue Sarcoma -2, Site: Retroperitoneum and peritoneum',
            value: 'TCGA-X6-A8C2-01Z-00-DX5.CDF34EFE-A8C3-4694-B2E7-D00B8F26B0C3/TCGA-X6-A8C2-01Z-00-DX5.CDF34EFE-A8C3-4694-B2E7-D00B8F26B0C3.svs',
            folder: 'TCGA-X6-A8C2-01Z-00-DX5.CDF34EFE-A8C3-4694-B2E7-D00B8F26B0C3'
        },
        // Add more slides as needed
    ];
    useEffect(() => {

        const fetchSlideURL = async () => {
            
            const url = await Storage.get(selectedSlide, { level: 'public' });
            console.log(url);
            setSlideURL(url);
        }

        if (selectedSlide !== "") {
            fetchSlideURL();
        }
        else {
            setSlideURL('');
        }
    }, [selectedSlide]);

    const handleSlideChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setSelectedSlide(selectedValue);

        // Find the slide object based on the selected value
        const selectedSlideObject = slides.find(slide => slide.value === selectedValue);

        // Set the selectedSlideID based on the found slide object
        const selectedSlideID = selectedSlideObject ? selectedSlideObject.id : '';
        setSelectedSlideID(selectedSlideID);
        const selectedSlideFolder = selectedSlideObject ? selectedSlideObject.folder : '';
        setSelectedSlideFolder(selectedSlideFolder);
    };

    return (

        <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"

        >
            <div>
                <h2>Demo</h2>
            </div>
            <WhiteCard>
                <SelectField
                    label="Demo slide available:"
                    onChange={handleSlideChange}
                    placeholder="Select a slide"
                    value={selectedSlide}>
                    {slides.map((slide) => (
                        <option key={slide.value} value={slide.value}>
                            {slide.label}
                        </option>
                    ))}
                </SelectField>
                <Flex direction="column" gap="2rem">
                    {slideURL && <ViewerComp
                        url={slideURL}
                        visibleMap={visibleMap}
                        slideParameters={{
                            userId: '1',
                            subjectId: '0',
                            slideId: selectedSlideID,
                            level: 'public',
                            slidePath: selectedSlideFolder
                        }}
                    />}
                </Flex>
                <Flex direction="column" paddingBottom="20px"></Flex>
                <InferencesSummaryTable
                    userId={'1'}
                    subjectId={'0'}
                    slideId={selectedSlideID}
                    visibleMap={visibleMap}
                    setVisibleMap={setVisibleMap}
                />
            </WhiteCard>
        </Flex>
    );
}
