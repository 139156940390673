import React from "react";

export const getTopThreeProbablities = (probabilities: object | undefined): object => {

    if (!probabilities) {
        return {};
    }

    // Sort the probabilities by value, descending
    const sortedProbabilities = Object.entries(probabilities).sort((a, b) => b[1] - a[1]);
    // Return the top three probabilities
    return sortedProbabilities.slice(0, 3).reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
}

export const stringifyProbabilities = (probabilities: object): JSX.Element[] => {
    // Cycle each property in the object, and produce a mini table of the key and value

    return Object.entries(probabilities).map(([key, value]) => {
        return (
            <div key={key}>
                <span>{toHumanReadableString(key)}</span>: <span>{value}</span>
            </div>
        );
    });
};

export const toHumanReadableString = (key: string): string => {
    // Special cases handling
    const specialCases: { [key: string]: string } = {
        "gist": "GIST",
        "dfsp": "DFSP",
        "sft": "SFT"
    };

    return key
        // Remove the "prob_" prefix if it exists
        .replace(/^prob_/, "")
        // Split the string by underscores
        .split("_")
        // Map each part to its transformed version
        .map((part: string) => {
            // Check for and handle special cases
            if (specialCases[part]) {
                return specialCases[part];
            }
            // Capitalize the first letter and add the rest of the string
            return part.charAt(0).toUpperCase() + part.slice(1);
        })
        // Join the parts back with spaces and remove parentheses
        .join(" ")
        .replace(/\(|\)/g, "");
};

export const isProdEnvironment = (): boolean => {
    return process.env.NODE_ENV === "production";
}

export const getMitoticIndex = (resultsObject: { MitoticIndex?: string } | undefined): string | undefined => {
    if (!resultsObject) {
        return undefined;
    }

    // Directly return the MitoticIndex if it exists in the results object
    return resultsObject.MitoticIndex;
};