import {Heading, Icon} from "@aws-amplify/ui-react";
import {MdOutlineFindInPage, MdOutlineTableChart, MdUpload} from "react-icons/md";
import {BsFillPeopleFill} from "react-icons/bs";
import WhiteCard from "../../components/WhiteCard";

export default function Dashboard() {


    return (
        <>
            <div>
                <h2>Dashboard</h2>
            </div>
            <WhiteCard>
                <div>
                    <Heading level={3}>Guide to Using the Application</Heading>
                    <ol>
                        <li>
                            <Icon as={BsFillPeopleFill} /> <strong>Add Subject:</strong> Start by adding basic information to configure a subject. Navigate to the <strong>"Subjects"</strong> section using the subject icon.
                        </li>
                        <li>
                            <Icon as={MdUpload} /> <strong>Upload Slides:</strong> You can then upload pathology slides associated with a subject. Navigate to the <strong>"Upload Slide"</strong> section using the upload icon. Slides will stay in the converting mode until they are ready for usage (1-3 mins). 
                        </li>
                        <li>
                            <Icon as={MdOutlineTableChart} /> <strong>View Slides:</strong> After slides are uploaded and converted, you can view all the slides under the <strong>"Slides"</strong> section. Use the table chart icon to navigate there.
                        </li>
                        <li>
                            <Icon as={MdOutlineFindInPage} /> <strong>Launch Inferences:</strong> In the slides section, you can request AI to run inferences on your slides. This process may take up to 10 minutes per slide; the results will appear once they are ready.
                        </li>
                    </ol>
                </div>
            </WhiteCard>
            <br/ >   
            <WhiteCard>
            <Heading level={3}>Disclaimer</Heading>
                <div>
                The AI algorithms provided by our research team are intended for research and informational purposes only and should not be used for clinical decision-making or patient care. The treating clinician remains responsible for all decisions and actions related to patient care, and our research team deny any liability arising from the misuse or improper application of these algorithms.
                </div><br/><div>
                In accordance with the General Data Protection Regulation (GDPR), we require that all data submitted to our algorithms be fully anonymized and free of any personally identifiable information (PII). Any data found to contain non-anonymized information will be automatically deleted from our systems without further notice.
                </div>
            </WhiteCard>
        
        </>
    );
}
