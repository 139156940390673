const awsConfig = {
    Auth: {
        region: 'eu-west-2', // Your Cognito Pool Region
        userPoolId: 'eu-west-2_yD3R1RSwa', // Your User Pool ID
        userPoolWebClientId: '4qc6a23p90vchb6hp5h9kv63v4', // Your Client ID
        identityPoolId: 'eu-west-2:1444e949-3a0f-4e0a-b0f7-62456b09bc58', // Your Identity Pool ID
    },
    Storage: {
        AWSS3: {
            bucket: 'histopathology-slides', // Your S3 Bucket Name
            region: 'eu-west-2', // Your S3 Bucket Region
        }
    },
    API: {
        endpoints: [
            {
                name: 'histopathologyAPI',
                endpoint: 'https://api.octopath-ai.com',
            }
        ]
    }
};

export default awsConfig;
