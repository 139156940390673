import { useEffect, useRef, useState } from "react";
import { paintAttentionMap, paintMitoses } from "./HeatmapHelper";
import FeedBackFormMitosis from '../components/FeedBackFormMitosis';
const OpenSeadragon = window.OpenSeadragon;

const ViewerComp = ({url, visibleMap, slideParameters}) => {
    const viewerRef = useRef(null);
    const heatmapRef = useRef(null);
    const [viewer, setViewer] = useState(null);
    const mpp = useRef(null);

    const paintHeatmap = async () => {
        if (viewer) {
            if (heatmapRef.current) {
                console.log("Clearning existing heatmap")
                heatmapRef.current.heatmap._renderer._clear();
                heatmapRef.current.setData({ max: 0, data: [] });
            }

            switch (visibleMap) {
                case 'attentionMap':
                    console.log("Painting attention map")
                    await paintAttentionMap(viewer, heatmapRef, slideParameters, mpp);
                    break;
                case 'mitosesMap':
                    console.log("Painting mitosis map")
                    await paintMitoses(viewer, heatmapRef, slideParameters);
                    break;
                default:
                    // No map is set to be visible
                    console.log("No map to paint")
                    break;
            }
        }
    }

    useEffect(() => {
        paintHeatmap();
    }, [visibleMap, viewer, slideParameters]);


    useEffect(() => {

        let newViewer = null;

        // Async function to load OpenSeadragon
        const loadViewer = async () => {

            if (!url || !OpenSeadragon) return null;

            if (viewer) {
                console.log("Destroying existing viewer");
                viewer.destroy();
                setViewer(null)
            }

            console.log("Loading viewer for: ", url);

            // Initialize OpenSeadragon - needs to be run in effect as it needs the DOM to be loaded
            newViewer = OpenSeadragon({
                element: viewerRef.current,
                prefixUrl: "https://openseadragon.github.io/openseadragon/images/",
                minZoomImageRatio: 0.01,
                visibilityRatio: 0,
                showNavigator: true,
                crossOriginPolicy: "Anonymous",
                ajaxWithCredentials: true,
                sequenceMode: true,
            });

            const tiffTileSources = await OpenSeadragon.GeoTIFFTileSource.getAllTileSources(url, { logLatency: false });
            mpp.current = tiffTileSources[0].dimensions.x;

            if (newViewer) { // Check if the component is still mounted after async call to GeoTIFFTileSource.getAllTileSources
                newViewer.open(tiffTileSources);
                setViewer(newViewer);
            }
        };

        // Call the async function
        loadViewer();

        // Cleanup function
        return () => {
            console.log("Cleaning up viewer")
            setViewer(null);
            if (newViewer) {
                newViewer.destroy();
                newViewer = null;
            }
        };
    }, [url]);


    return (
        <div>
            <div id="viewer" ref={viewerRef} style={{
                position: "relative",
                width: "800px",
                height: "600px",
                border: "thin black solid",
                borderRadius: "3px",
                boxShadow: "0 5px 10px 0px gray"
            }}>
            </div>

            {visibleMap=="mitosesMap" &&
                <FeedBackFormMitosis
                 slideParameters={slideParameters}
                    resultsFilename="mitoses_result.csv"
                    viewer={viewer}

                />
            }

        </div>
    );
};

export default ViewerComp;
