import * as React from "react";
import {PropsWithChildren} from "react";
import {Card} from "@aws-amplify/ui-react";

export default function WhiteCard({children} : PropsWithChildren) {
    return (
        <Card
                backgroundColor="var(--amplify-colors-white)"
                borderRadius="6px"
                maxWidth="1000px"
                minWidth="860px"
                padding="1rem"
                variation="elevated"
            >
            {children}
        </Card>
    )
}