// import { useState } from "react";
import { Heading } from "@aws-amplify/ui-react";
import { SubjectSelectField } from "../../components/SubjectSelectField";
import { useRecoilValue } from "recoil";
import { userIdSelector } from "../../recoil/uiState";
import SlidesTable from "../../feature/SlidesTable/SlidesTable";
// import DeleteAllSlidesButton from "../../components/DeleteAllSlidesButton";
import { useNavigate, useParams } from "react-router-dom";
import WhiteCard from "../../components/WhiteCard";

export default function Slides() {
    // const [isModalOpen, setIsModalOpen] = useState(false);

    const userId = useRecoilValue(userIdSelector)
    const { subjectId } = useParams();
    const navigate = useNavigate();

    const selectedSubjectId = subjectId ? subjectId : "";
    const showTable = userId !== "" && selectedSubjectId !== "";

    // const handleDelete = () => {
    //     console.log("Delete logic goes here");
    //     setIsModalOpen(false);
    // };
    const handleSubjectSelection = (id: string) => {
        if (!id) {navigate(`/slides`);}
        else{navigate(`/subjects/${id}/slides`);}
    };

    return (
        <>
            <div>
                <h2>Slides</h2>
            </div>
            <WhiteCard>
                <SubjectSelectField
                    showAddButton={false}
                    selectedSubjectId={selectedSubjectId}
                    setSelectedSubjectId={handleSubjectSelection}
                />
                {showTable && <div style={{ marginBlock: "1rem" }}>
                    <SlidesTable subjectId={selectedSubjectId} userId={userId} />
                </div>}
                {!showTable && <>
                    <Heading color="#333"> Select a subject to see their slides </Heading>
                </>}
            </WhiteCard>
        </>

    );
}
