import { useEffect, useState } from "react";
import { Table,  TableBody, TableCell, TableHead, TableRow, CheckboxField, Badge, Text, Loader } from "@aws-amplify/ui-react";
import { API, Storage } from "aws-amplify";
import { Link } from "react-router-dom";
import TableHeader from "./TableHeader";
import { Slide, ThumbnailUrls, Inference } from "./types";


const generatePresignedURL = async (thumbnailURL: string | null) => {
    if (!thumbnailURL) return "";
    const url = await Storage.get(thumbnailURL, { level: 'protected' });
    return url;
}
const SlidesTable = ({ userId, subjectId }: { userId: string, subjectId: string }) => {
    const [slides, setSlides] = useState<Slide[]>([]); // Use the Slide interface for typing the state
    const [thumbnailUrls, setThumbnailUrls] = useState<ThumbnailUrls>({});
    const noSlides = slides.length === 0;
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [inferences, setInferences] = useState<Inference[]>([]);

    useEffect(() => {
        const fetchInferencesForSlide = async () => {
          try {
            const response = await API.get('histopathologyAPI', `/users/${userId}/subjects/${subjectId}/slides/all/inferences`, {});
            if (JSON.stringify(response) !== JSON.stringify(inferences)) {
              setInferences(response);
            }
          } catch (error) {
            console.error('Error fetching inferences for subject:', subjectId, error);
          }
        };
      
        fetchInferencesForSlide();
        const interval = setInterval(fetchInferencesForSlide, 5000);
        return () => clearInterval(interval);
      }, [userId, subjectId, slides, inferences]);
      

    const handleSelectAllChange = () => {
        if (!selectAll) {
            // If currently not all selected, select all
            setSelectedRows(slides.map(slide => slide.id));
        } else {
            // If currently all selected, deselect all
            setSelectedRows([]);
        }
        setSelectAll(!selectAll);
    };

    const handleRowCheckboxChange = (slideId: string) => {
        if (selectedRows.includes(slideId)) { // already in
            setSelectedRows(selectedRows.filter(id => id !== slideId));

        } else {
            setSelectedRows([...selectedRows, slideId]);
        }
    };
    const fetchSlides = async () => {
        if (userId && subjectId) {
            try {
                const response = await API.get('histopathologyAPI', `/users/${userId}/subjects/${subjectId}/slides`, {});
                const slides: Slide[] = response; // Type assertion
                setSlides(response);
                const urls = await Promise.all(slides.map(slide =>
                    generatePresignedURL(`subjects/${subjectId}/slides/${slide.id}/thumbnail.jpg`)
                        .then(url => ({ [slide.id]: url }))
                ));

                setThumbnailUrls(Object.assign({}, ...urls));
            } catch (error) {
                console.error('Error fetching slides:', error);
            }
        }
    };
    useEffect(() => {
        fetchSlides();
    }, [userId, subjectId]);

    return (
        <>
            {!noSlides &&
                <>
                    <TableHeader
                        selectedRows={selectedRows}
                        userId={userId}
                        subjectId={subjectId}
                        fetchSlides={fetchSlides}
                        setSelectAll={setSelectAll}
                        setSelectedRows={setSelectedRows}

                    />

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <button
                                        onClick={handleSelectAllChange}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            padding: 0,
                                            font: 'inherit',
                                            color: 'blue',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Select All
                                    </button>
                                </TableCell>
                                <TableCell>Slide ID</TableCell>
                                <TableCell>Thumbnail</TableCell>
                                <TableCell>Slide Filename</TableCell>
                                <TableCell>Inferences</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {slides.map((slide, index) => (
                                <TableRow key={slide.id}>

                                    <TableCell>
                                        <CheckboxField
                                            label=""
                                            name=""
                                            value="yes"
                                            checked={selectedRows.includes(slide.id)}
                                            onChange={() => handleRowCheckboxChange(slide.id)}
                                        />
                                    </TableCell>
                                    <TableCell>{slide.id}</TableCell>
                                    <TableCell>
                                        {slide.isconverted ?
                                            (<img width="100" src={thumbnailUrls[slide.id] || ''} />) :
                                            (<Loader />)
                                        }
                                    </TableCell>
                                    <TableCell>{slide.filename}</TableCell>
                                    <TableCell>

                                        {inferences.filter(inference => inference.slide_id === slide.id)
                                            .map((inference: Inference) => (
                                                <Badge
                                                    key={inference.inference_type}
                                                    style={{ marginBottom: '4px' }}
                                                    variation={inference.job_status !== "SUCCESS" ? 'info' : 'success'}
                                                >
                                                    {inference.inference_type}
                                                </Badge>
                                            ))
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {slide.isconverted ?
                                            (
                                                <Link to={`/subjects/${subjectId}/slides/${slide.id}/view`}>View Slide</Link>
                                            ) :
                                            (
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Text>Converting..</Text>
                                                    <Loader />
                                                </div>
                                            )}


                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            }
        </>
    );
};

export default SlidesTable;
