import { Flex, Image, Text, Button, Divider } from "@aws-amplify/ui-react";
import "./FrontPage.css";
import { TiSocialLinkedin } from "react-icons/ti";
import { MdEmail } from "react-icons/md";
import React, { useState } from 'react';
import AlgorithmPopup from '../../components/AlgorithmPopup';
import { Algorithm } from '../../components/AlgorithmPopup'
import algorithms from '../../data/algorithmdata.json';
import { FaGraduationCap   } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';


const teamMembers = [
    { name: "Charles-Antoine Collins-Fekete", role: "Principal Research Fellow", email: "c.fekete@ucl.ac.uk", image: "CACF.png", academicProfile: "https://profiles.ucl.ac.uk/65994-charlesantoine-collins-fekete", linkedinProfile:"https://www.linkedin.com/in/charles-antoine-collins-fekete-04288757/" },
    { name: "Mikael Simard", role: "Senior Research Fellow", email: "m.simard@ucl.ac.uk", image: "MS.png", academicProfile: "https://profiles.ucl.ac.uk/87006-mikael-simard", linkedinProfile:"https://www.linkedin.com/in/mika%C3%ABl-simard-56511b73/" },
    { name: "Zhuoyan Shen", role: "Research Fellow", email: "z.shen@ucl.ac.uk", image: "ZS.png", academicProfile: "https://profiles.ucl.ac.uk/87006-mikael-simard", linkedinProfile:"https://www.linkedin.com/in/zhuoyan-shen-38a29127b/?originalSubdomain=uk"},

];

export default function FrontPage() {

    return (
        <Flex className="mainContainer">
            <Header />
            <ColorBox />
            <AlgoSection algorithms={algorithms} />
            <TeamSection members={teamMembers} />
            <Partners />
            <BottomBanner />
        </Flex>
    );
}

function Header() {
    return (
        <div className="imageContainer" style={{backgroundImage: "url(./HeroHeader2.svg)"}}>

            <div className="textHeading">
                ARTIFICIAL INTELLIGENCE SOLUTIONS FOR DIGITAL PATHOLOGY
            </div>
        </div>
    );
}

function ColorBox() {
    return (
        <Flex className="colorboxcontainer">
            <Text className="colorBoxText">
                At Octopath, we aim to provide AI-powered solutions to assist pathologists in performing their daily tasks. Our
                goal is to accelerate diagnosis workflows so that clinicians can focus on improving patient outcomes and care.
            </Text>
            <Button className="colorBoxButton" loadingText="" onClick={() => window.location.href = '/dashboard'}>
                Get started!
            </Button>
        </Flex>
    );
}

function AlgoSection({ algorithms }: { algorithms: Algorithm[] }) {
    const [selectedAlgorithm, setSelectedAlgorithm] = useState<Algorithm | null>(null);
    const openPopup = (algorithm: Algorithm) => {
        setSelectedAlgorithm(algorithm);
    };

    const closePopup = () => {
        setSelectedAlgorithm(null);
    };
    return (
        <div className="algo-container">
            <div className="section-header">OUR AI MODELS</div>
            <Divider className="dividerstyle" />
            <Flex className="algo-grid-container">
                {algorithms.map((algo) => (
                    <Flex key={algo.id} className="algo">
                        <div onClick={() => openPopup(algo)}>
                            <Image src={algo.images[0]} alt={`${algo.title} - Thumbnail`} />
                            <Text textAlign="center">{algo.title}</Text>
                        </div>
                    </Flex>
                ))}
            </Flex>
            {/* Algorithm Popup */}
            {selectedAlgorithm && (
                <AlgorithmPopup algorithm={selectedAlgorithm} onClose={closePopup} />
            )}
        </div>

    );
}

function TeamSection({ members }: { members: typeof teamMembers }) {

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1, // Adjust the threshold as needed
      });

    return (
        <div ref={ref} className={`algo-container ${inView ? 'animate' : ''}`}>
            <div className="section-header">OUR TEAM</div>
            <Divider className="dividerstyle" />
            <div className="member-grid">
                {members.map((member, index) => (
                    <div key={index} className="members">
                        <Image className="photos" src={member.image} alt="" />
                        <Text className="text" fontWeight="bold">{member.name}</Text>
                        <Text className="text">{member.role}</Text>
                        <Text className="text">University College London</Text>
                        <Flex className="icons-container">
                            <a href={`mailto:${member.email}`} className="email-icon">
                                <MdEmail className="styles-icon-mail" />
                            </a>
                            <a href={member.linkedinProfile} target="_blank" rel="noopener noreferrer" className="email-icon">
                            <TiSocialLinkedin className="styles-icon" />
                            </a>
                            <a href={member.academicProfile} target="_blank" rel="noopener noreferrer" className="email-icon">
                                <FaGraduationCap   className="styles-icon" />
                            </a>
                        </Flex>

                    </div>
                ))}
            </div>
        </div>
    );
}




function BottomBanner() {
    return (
        <Flex className="mainContainer">
            <div className="bottom-banner">
                <Image className="bottom-banner-image" src="UCL.png" alt="" />
            </div>
        </Flex>
    );
}
function Partners() {
    return (
        <div className="algo-container">
            <div className="section-header">OUR PARTNERS</div>
            <Divider className="dividerstyle" />
            <div className="logo-grid">
                <Image src="University_College_London_Hospitals_NHS_Foundation_Trust_logo.svg" alt="UKRI Logo" />
                <Image src="RNOH_logo.jpg" alt="Future Leaders Fellowship Logo" />
                <Image src="2560px-Amazon_Web_Services_Logo.svg.png" alt="Sarcoma UK Logo" />
                <Image src="UCL.svg" alt="UCL Logo" />
                <Image src="UKRI_Ai4Health.png" alt="Future Leaders Fellowship Logo" />
                <Image src="Sarcomalogo.png" alt="Sarcoma UK Logo" />
                {/* Add more logos as needed */}
            </div>
        </div>
    );
}
