import { Authenticator, Loader, WithAuthenticatorProps } from "@aws-amplify/ui-react";
import { Link, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import { RecoilRoot } from "recoil";
import { Suspense } from "react";
import { ThemeProvider, Theme } from '@aws-amplify/ui-react';

// Authed pages
import Dashboard from "./pages/authed/Dashboard";
import Demo from "./pages/public/Demo";
import InferenceDetail from "./pages/authed/InferenceDetail";
import Slides from "./pages/authed/Slides";
import SlideDetail from "./pages/authed/SlideDetail";
import UploadSlide from "./pages/authed/UploadSlide";
import Subjects from "./pages/authed/Subjects";
import SubjectDetail from "./pages/authed/SubjectDetail";

// Public pages
import FrontPage from "./pages/public/FrontPage";
import Vision from "./pages/public/Vision";
import Career from "./pages/public/Career";
import Collection from "./pages/public/Collection"
interface Props extends WithAuthenticatorProps {
    isPassedToWithAuthenticator: boolean;
}

function App({ isPassedToWithAuthenticator, signOut, user }: Props) {
    return <>
        <div>
            <ThemeProvider theme={theme}>
                <Authenticator.Provider>
                    <RecoilRoot>
                        <Suspense fallback={<Loader />}>
                            <Routes>
                                <Route path="/" element={<Layout />}>
                                    <Route index element={<FrontPage />} />
                                    <Route path="vision" element={<Vision />} />
                                    <Route path="demo" element={<Demo />} />
                                    <Route path="career" element={<Career />} />
                                    <Route path="collection" element={<Collection />} />
                                    <Route path="dashboard" element={
                                        <Authenticator>
                                            <Dashboard />
                                        </Authenticator>
                                    } />

                                    <Route path="upload" element={
                                        <Authenticator>
                                            <UploadSlide />
                                        </Authenticator>
                                    } />
                                    <Route path="subjects/:subjectId/upload" element={
                                        <Authenticator>
                                            <UploadSlide />
                                        </Authenticator>
                                    } />
                                    <Route path="subjects" element={
                                        <Authenticator>
                                            <Subjects />
                                        </Authenticator>
                                    } />
                                    <Route path="slides" element={
                                        <Authenticator>
                                            <Slides />
                                        </Authenticator>
                                    } />
                                    <Route path="subjects/:subjectId/slides" element={
                                        <Authenticator>
                                            <Slides />
                                        </Authenticator>
                                    } />
                                    <Route path="subjects/:subjectId/view" element={
                                        <Authenticator>
                                            <SubjectDetail />
                                        </Authenticator>
                                    } />
                                    <Route path="subjects/:subjectId/slides/:slideId/view" element={
                                        <Authenticator>
                                            <SlideDetail />
                                        </Authenticator>
                                    } />
                                    <Route path="subjects/:subjectId/slides/:slideId/inferences/:inferenceId/view" element={
                                        <Authenticator>
                                            <InferenceDetail />
                                        </Authenticator>
                                    } />
                                    <Route path="*" element={<NoMatch />} />
                                </Route>
                            </Routes>
                        </Suspense>
                    </RecoilRoot>
                </Authenticator.Provider>
            </ThemeProvider>
        </div>
    </>;
}

export default App;


function NoMatch() {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center'
        }}>
            <h2>You are </h2>
            <img
                src={`${process.env.PUBLIC_URL}/404.png`} alt="Not Found"
                width="300"
                height="300"
            />
            <p>
                <Link to="/">Go to Home </Link>
            </p>
        </div>
    );
}

const theme: Theme = {
    name: 'my-theme',
    tokens: {
        components: {
            authenticator: {
                form: {
                    padding: { value: '10px' }, // Changed 'padding-top' to 'paddingTop'
                },
            },
        },
        fonts: {
            default: {
                variable: { value: 'Montserrat, sans-serif' },
                static: { value: 'Montserrat, sans-serif' },
            },
        },
    },
};

