import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { baseConfig } from "../../config";
import SideBar from "../SideBar";
import Header from "../Header";
import Footer from "../Footer";
import "./Layout.css";
import { useAuthenticator } from "@aws-amplify/ui-react";

export interface LayoutProps {
    children?: React.ReactNode;
}

const Layout = (props: any) => {
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const loggedIn = !!user;
    const location = useLocation();
    const publicRoutes = ["/", "/vision", "/team", "/career", "/demo","/collection"];
    const isPublicPage = publicRoutes.includes(location.pathname);

    return (
        <div className="layout-container">
            <Header loggedIn={loggedIn} isPublicPage={isPublicPage} />
            {loggedIn && !isPublicPage && <SideBar />}
            
            {loggedIn ?
                <div className={isPublicPage ? "page-container-public" : "page-container"}>
                    <Outlet />
                </div> :
                <div className={"page-container-public"}>
                    <Outlet />
                </div>
                }
        
            {baseConfig.footer ? <Footer /> : <></>}
        </div>
    );
};

export default Layout;
