// inferenceUtils.ts
import { API, Auth, Storage } from "aws-amplify";
import JSZip from "jszip";
import { saveAs } from "file-saver";
export async function submitInference(
  userId: string,
  subjectId: string,
  slideIds: string[],
  selectedInference: { InferenceType: string } | undefined,
  modelVersion: string
) {
  const credentials = await Auth.currentCredentials();
  const identityID = credentials.identityId;
  const session = await Auth.currentSession(); // Get the current session
  const idToken = session.getIdToken().getJwtToken(); // Get the ID Token

  const responses = [];
  for (let i = 0; i < slideIds.length; i++) {
    const slideId = slideIds[i];
    console.log('here');
    const response = await API.post(
      "histopathologyAPI",
      `/users/${userId}/subjects/${subjectId}/slides/${slideId}/inferences`,
      {
        body: {
          modelVersion: modelVersion,
          inference_type: selectedInference?.InferenceType,
          cognito_identity_id: identityID,
        },
        headers: {
          Authorization: idToken,
        },
      }
    );
    console.log("Post inferences response:", response);
    responses.push(response);
  }

  return responses;
}  

export async function downloadAIResults(
  userId: string,
  subjectId: string,
  slideIds: string[]
) {
  const zip = new JSZip();
  const credentials = await Auth.currentCredentials();
  const identityID = credentials.identityId;
  const session = await Auth.currentSession();
  const idToken = session.getIdToken().getJwtToken();

  for (const slideId of slideIds) {
    const filePath = `subjects/${subjectId}/slides/${slideId}/mitoses_result.csv`;
    try {
      const downloadedFile: any = await Storage.get(filePath, {
        download: true,
        level: 'protected',
      });
      console.log(downloadedFile);
      if (downloadedFile.Body) {
        const fileData = await downloadedFile.Body.arrayBuffer();
        zip.file(filePath, fileData);
      }
    } catch (error) {
      console.warn(`File not found for slide ${slideId}. Skipping...`);
    }
  }

  const zipBlob = await zip.generateAsync({ type: "blob" });
  saveAs(zipBlob, "ai_results.zip");
}
