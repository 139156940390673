// JoinUs.tsx
import React, { useState } from 'react';
import { Flex, Card, Heading, Text, Button, Input } from "@aws-amplify/ui-react";
import "./Career.css";
import WhiteCard from "../../components/WhiteCard";

interface JobPosition {
  id: number;
  title: string;
  description: string;
  responsibilities: string[];
  skillsRequired: string[];
  desirables: string[];
  employeeBenefits: string[];
  personSpecification: string[];
}

const jobPositions: JobPosition[] = [
  {
    id: 1,
    title: "Software Engineer",
    description: "We are looking for a skilled software engineer to join our team.",
    responsibilities: ["Develop and maintain software applications", "Collaborate with cross-functional teams"],
    skillsRequired: ["Proficiency in programming languages", "Knowledge of software development methodologies"],
    desirables: ["Experience with cloud computing platforms", "Familiarity with agile development"],
    employeeBenefits: ["Competitive salary", "Health insurance", "Paid time off"],
    personSpecification: ["Strong problem-solving skills", "Excellent communication skills"]
  },
  {
    id: 3,
    title: "Software Engineer",
    description: "We are looking for a skilled software engineer to join our team.",
    responsibilities: ["Develop and maintain software applications", "Collaborate with cross-functional teams"],
    skillsRequired: ["Proficiency in programming languages", "Knowledge of software development methodologies"],
    desirables: ["Experience with cloud computing platforms", "Familiarity with agile development"],
    employeeBenefits: ["Competitive salary", "Health insurance", "Paid time off"],
    personSpecification: ["Strong problem-solving skills", "Excellent communication skills"]
  },

  {
    id: 2,
    title: "Research Scientist",
    description: "We are looking for a skilled research scientist to join our team.",
    responsibilities: ["Develop and maintain software applications", "Collaborate with cross-functional teams"],
    skillsRequired: ["Proficiency in programming languages", "Knowledge of software development methodologies"],
    desirables: ["Experience with cloud computing platforms", "Familiarity with agile development"],
    employeeBenefits: ["Competitive salary", "Health insurance", "Paid time off"],
    personSpecification: ["Strong problem-solving skills", "Excellent communication skills"]
  },
  // Add more job positions with similar structure
];

export default function Career() {
  const [selectedPosition, setSelectedPosition] = useState<JobPosition | null>(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    cv: null as File | null,
    description: "",
  });

  const handleCardClick = (position: JobPosition) => {
    setSelectedPosition(position);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setFormData((prevData) => ({
      ...prevData,
      cv: file || null,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Perform email submission logic here
    console.log("Form submitted:", formData);
    // Reset form data and close the popup
    setFormData({
      name: "",
      email: "",
      cv: null as File | null,
      description: "",
    });
    setSelectedPosition(null);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div>
        <h2>Help us make a difference</h2>
      </div>
      <WhiteCard>
        <Flex className="job-positions-list" direction="column">
          {jobPositions.map((position) => (
            <Card
              key={position.id}
              className="job-position-card"
              onClick={() => handleCardClick(position)}
              variation="elevated"
            >
              <Heading level={4}>{position.title}</Heading>
              <Text>{position.description}</Text>
            </Card>
          ))}
        </Flex>
        {selectedPosition && (
          <div className="career-popup-overlay">
            <div className="career-popup-content">
              <Heading level={2}>Apply for {selectedPosition.title}</Heading>
              <div className="job-details">
                <div>
                  <Heading level={4}>Responsibilities:</Heading>
                  <ul>
                    {selectedPosition.responsibilities.map((responsibility, index) => (
                      <li key={index}>{responsibility}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <Heading level={4}>Skills Required:</Heading>
                  <ul>
                    {selectedPosition.skillsRequired.map((skill, index) => (
                      <li key={index}>{skill}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <Heading level={4}>Desirables:</Heading>
                  <ul>
                    {selectedPosition.desirables.map((desirable, index) => (
                      <li key={index}>{desirable}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <Heading level={4}>Employee Benefits:</Heading>
                  <ul>
                    {selectedPosition.employeeBenefits.map((benefit, index) => (
                      <li key={index}>{benefit}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <Heading level={4}>Person Specification:</Heading>
                  <ul>
                    {selectedPosition.personSpecification.map((specification, index) => (
                      <li key={index}>{specification}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <Input
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                <Input
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                <div className="file-upload">
                  <label htmlFor="cv">Upload your Curriculum Vitae:</label>
                  <Input
                    id="cv"
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    required
                  />
                </div>
                <textarea
                  name="description"
                  placeholder="Why are you a good fit for this position?"
                  value={formData.description}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      description: e.target.value,
                    }))
                  }
                  required
                ></textarea>
                <Button type="submit">Submit Application</Button>
              </form>
              <Button onClick={() => setSelectedPosition(null)}>Close</Button>
            </div>
          </div>
        )}
      </WhiteCard>
    </div>
  );
}
