import * as React from "react";
import {useState} from "react";
import {Alert, Button, ButtonVariations, Flex, View} from "@aws-amplify/ui-react";
import {MdDelete} from "react-icons/md";
import {getUserID} from "../api/api";
import {API} from "aws-amplify";
import {useRecoilValue} from "recoil";
import {userIdSelector} from "../recoil/uiState";

export default function DeleteSubjectButton({selectedSubjectId, onDelete, buttonVariation}: {selectedSubjectId: string, onDelete: (id:string) => void, buttonVariation: ButtonVariations}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const userId = useRecoilValue(userIdSelector)

    const showButton = selectedSubjectId !== "";

    const handleDelete = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const userID = await getUserID();
            const response = await API.del("histopathologyAPI", `/users/${userID}/subjects/${selectedSubjectId}`, {
                body: { id: selectedSubjectId }
            });

            setIsModalOpen(false);
            onDelete(selectedSubjectId);
        } catch (error) {
            console.error("Error deleting subject:", error);
        }
    };

    return (
        <>
            {showButton &&
                <View padding="1rem">
                    <Button onClick={() => setIsModalOpen(true)} variation={buttonVariation} colorTheme="error" size="small">
                        <MdDelete size="20" /> Delete Subject
                    </Button>
                    {isModalOpen &&
                        <Alert
                            isDismissible={false}
                            hasIcon={false}
                            heading="Are you sure you want to delete this subject, and all their slides?"
                            style={{ marginBlock: "2rem" }}
                        >
                            <form onSubmit={handleDelete}>
                                <Flex direction="column" gap="small" padding="1rem">
                                    <Flex direction="row" justifyContent="space-between">
                                        <Button type="submit" variation="primary" colorTheme="error">Confirm
                                            Delete</Button>
                                        <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                                    </Flex>
                                </Flex>

                            </form>
                        </Alert>
                    }
                </View>
            }
        </>
    );
}
