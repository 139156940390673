import {useEffect, useState} from "react";
import {Breadcrumbs, Button, Input} from "@aws-amplify/ui-react";
import {useRecoilValue} from "recoil";
import {userIdSelector} from "../../recoil/uiState";
import {API} from "aws-amplify";
import DeleteSubjectButton from "../../components/DeleteSubjectButton";
import {useParams} from "react-router-dom";
import WhiteCard from "../../components/WhiteCard";

interface Subject {
    SubjectID: string;
    name: string;
    age: string;
    diagnosis: string;
}

export default function SubjectDetail() {
    const userId = useRecoilValue(userIdSelector);
    const [subject, setSubject] = useState<Subject | null>(null);
    const { subjectId } = useParams();
    const selectedSubjectId = subjectId ? subjectId : "";

    const [editedName, setEditedName] = useState<string>("");
    const [editedAge, setEditedAge] = useState<string>("");
    const [editedDiagnosis, setEditedDiagnosis] = useState<string>("");

    useEffect(() => {
        const fetchSubjectDetails = async () => {
            if (userId && subjectId) {
                try {
                    const response = await API.get("histopathologyAPI", `/users/${userId}/subjects/${selectedSubjectId}`, {});
                    setSubject(response[0]);
                    setEditedName(response[0].name);
                    setEditedAge(response[0].age);
                    setEditedDiagnosis(response[0].diagnosis);
                } catch (error) {
                    console.error("Error fetching subject details:", error);
                }
            }
        };
        fetchSubjectDetails();
    }, [userId, subjectId]);

    const handleUpdateSubject = async () => {
        try {
            await API.put("histopathologyAPI", `/users/${userId}/subjects/${selectedSubjectId}`, {
                body: {
                    name: editedName,
                    age: editedAge,
                    diagnosis: editedDiagnosis
                }
            });
            // Optionally, you can update the subject state here if needed
        } catch (error) {
            console.error("Error updating subject:", error);
        }
    };

    return (
        <>
            <Breadcrumbs
                items={[
                    {
                        href: '/subjects',
                        label: 'Subjects',
                    },
                    {
                        label: 'Subject ' + subjectId,
                    }
                ]}
            />
            <div>
                <h2>Subject {subjectId} Details</h2>
            </div>
            <WhiteCard>
                <h3>Subject Information</h3>
                <p>Subject ID: {subject?.SubjectID}</p>
                <label>
                    Subject Identifier:
                    <Input
                        type="text"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                    />
                </label>
                <label>
                    Subject Age:
                    <Input
                        type="text"
                        value={editedAge}
                        onChange={(e) => setEditedAge(e.target.value)}
                    />
                </label>
                <label>
                    Subject Diagnosis:
                    <Input
                        type="text"
                        value={editedDiagnosis}
                        onChange={(e) => setEditedDiagnosis(e.target.value)}
                    />
                </label>
                <Button onClick={handleUpdateSubject}>Save Changes</Button>
            </WhiteCard>
            <DeleteSubjectButton
                selectedSubjectId={selectedSubjectId}
                onDelete={() => {
                    console.log("Delete logic goes here");
                }}
                buttonVariation="primary"
            />
        </>
    );
}
