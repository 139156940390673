import {atom, selector} from "recoil";
import {getUserID} from "../api/api";

const userIdState = atom({
    key: 'userIdState',
    default: '',
});

export const userIdSelector = selector({
    key: 'userIdSelector',
    get: async ({get}) => {
        const currentUserId = get(userIdState);

        if (currentUserId && currentUserId !== '') return currentUserId; // Return cached ID if available

        try {
            const userID = await getUserID();
            return userID;
        } catch (error) {
            throw error;
        }
    },
    set: ({set}, newValue) => {
        set(userIdState, newValue);
    }
});
