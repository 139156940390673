import React, { useEffect, useState } from "react";
import { Badge, Button, Heading, Table, TableBody, TableCell, TableHead, TableRow } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { getTopThreeProbablities, stringifyProbabilities, getMitoticIndex} from "../utils";

// Define the structure of each inference object
interface Inference {
    results: object | undefined;
    job_status: string;
    job_progress: string;
    inference_type: string;
    id: string;
}

interface InferencesSummaryTableProps {
    userId: string;
    subjectId: string;
    slideId: string;
    visibleMap: string | null;
    setVisibleMap: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function InferencesSummaryTable({
    userId,
    subjectId,
    slideId,
    visibleMap,
    setVisibleMap,
}: InferencesSummaryTableProps) {
    let navigate = useNavigate();
    const [inferences, setInferences] = useState<Inference[]>([]);

    useEffect(() => {
        // Define the function to fetch inferences
        const fetchInferences = async () => {
            if (userId && subjectId && slideId) {
                try {
                    const response = await API.get('histopathologyAPI', `/users/${userId}/subjects/${subjectId}/slides/${slideId}/inferences`, {});
                    setInferences(response);
                    console.log(response);
                } catch (error) {
                    console.error('Error fetching inferences:', error);
                }
            }
        };

        fetchInferences();
        const intervalId = setInterval(fetchInferences, 5000); // Fetch every 5 seconds

        return () => clearInterval(intervalId);
    }, [userId, subjectId, slideId]);


    const handleRemoveInferenceClicked = async (id: string) => {
        try {
            await API.del('histopathologyAPI', `/users/${userId}/subjects/${subjectId}/slides/${slideId}/inferences/${id}`, {});
            setInferences(inferences.filter(inference => inference.id !== id));
        } catch (error) {
            console.error('Error removing inference:', error);
        }
    }

    const handleDetailsInferenceClicked = (inferenceID: string) => {
        navigate(`/subjects/${subjectId}/slides/${slideId}/inferences/${inferenceID}/view`);
    }

    const handleViewInferenceClicked = (inferenceID: string) => {
        if (inferenceID == "MIT") setVisibleMap(visibleMap !== "mitosesMap" ? "mitosesMap" : null);
        else if (inferenceID == "SAC") setVisibleMap(visibleMap !== "attentionMap" ? "attentionMap" : null);
    }

    return (
        <div>
            <Heading color="#333" textAlign="center">Completed Inferences</Heading>
            <br />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Inference ID</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Info</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inferences.map((inference, index) => (
                        <TableRow key={inference.inference_type}>
                            <TableCell>{inference.inference_type}</TableCell>
                            <TableCell>
                                <Badge variation={inference.job_status !== "SUCCESS" ? 'info' : 'success'}>
                                    {inference.job_status}
                                </Badge>
                            </TableCell>
                            <TableCell>
                                <div>
                                    {inference.job_status !== "SUCCESS" ? `Inference is still in progress: ${Math.round(parseFloat(inference.job_progress))}%` :
                                        inference.inference_type == 'SAC' && inference.results ?
                                            stringifyProbabilities(getTopThreeProbablities(inference.results)) :
                                            inference.inference_type == 'MIT' && inference.results ? `Mitotic index: ${getMitoticIndex(inference.results)}` :
                                                null
                                    }
                                </div>
                                <div>
                                    {inference.job_status == "SUCCESS" &&
                                        <Button variation="link"
                                            onClick={() => handleDetailsInferenceClicked(inference.id)}>
                                            ...
                                        </Button>
                                    }
                                </div>
                            </TableCell>
                            <TableCell>
                                {inference.job_status == "SUCCESS" &&
                                    <Button variation="link"
                                        onClick={() => handleViewInferenceClicked(inference.inference_type)}>
                                        View
                                    </Button>}
                                <Button variation="link"
                                    onClick={() => handleRemoveInferenceClicked(inference.id)}>
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div >
    );
};

