import {useEffect, useState} from "react";
import {Breadcrumbs, Card, View} from "@aws-amplify/ui-react";
import {useParams} from "react-router-dom";
import {API} from "aws-amplify";
import {useRecoilValue} from "recoil";
import {userIdSelector} from "../../recoil/uiState";
import {stringifyProbabilities} from "../../utils";

// Define the structure of each inference object
interface Inference {
    id: string;
    inference_type: string;
    results: object;
}

export default function InferenceDetail() {
    const userId = useRecoilValue(userIdSelector);
    const [inference, setInference] = useState<Inference | null>(null); // Use the Inference interface for typing the state
    const { inferenceId, subjectId, slideId } = useParams();

    const [inferences, setInferences] = useState<Inference[]>([]); // Use the Inference interface for typing the state

    // If user, slide, inference or subjects Ids are undefined then immediately redirect to the home page
    const allParamsValid = slideId !== undefined && inferenceId !== undefined && subjectId !== undefined;

    if (!allParamsValid) {
        window.location.href = "/";
    }

    useEffect(() => {
        const fetchInferences = async () => {
            if (userId && subjectId && slideId) {
                try {
                    const response = await API.get("histopathologyAPI", `/users/${userId}/subjects/${subjectId}/slides/${slideId}/inferences/${inferenceId}`, {});
                    setInference(response[0]);
                } catch (error) {
                    console.error("Error fetching inferences:", error);
                }
            }
        };

        fetchInferences();
    }, [userId, subjectId, slideId]);

    return (
        <>
            <Breadcrumbs
                items={[
                    {
                        href: '/slides',
                        label: 'Slide Table',
                    },
                    {
                        href: `/subjects/${subjectId}/slides/${slideId}/view`,
                        label: 'Slide ' + slideId,
                    },
                    {
                        label: 'Inference ' + inferenceId
                    },
                ]}
            />
            <div>
                <h2>Inference {inferenceId} for Slide {slideId} Details</h2>
            </div>
            <View
                backgroundColor="var(--amplify-colors-white)"
                borderRadius="6px"
                maxWidth={{ base: "100%", large: "70%" }}
                padding="1rem"
            >
                {inference &&
                    <Card
                        variation="elevated">
                        {stringifyProbabilities(inference.results)}
                    </Card>}

            </View>
        </>

    );
}
