import React from "react";
import "../Layout/Layout.css";
import { baseConfig } from "../../config";
import { Flex, Text, Link } from "@aws-amplify/ui-react";
import Logo from "../Logo";
import HeaderNav from "./HeaderNav";

type HeaderProps = {
  loggedIn: boolean;
  isPublicPage: boolean;
};

const Header_public: React.FC<HeaderProps> = ({ loggedIn, isPublicPage }) => {
  return (
    <div className="header">
        <div className="header-left">
          <div className="header-logo">
            <Link href="/">
              <Logo />
            </Link>
          </div>
          <span>{baseConfig.headerText}</span>
        </div>
        {isPublicPage &&
          <div className="nav-text-container" >
            <Text>
              <Link href="/vision">
                VISION
              </Link>
            </Text>
            <Text>
              <Link href="/">
                TEAM
              </Link>
            </Text>
            <Text>
              <Link href="/career">
                CAREER
              </Link>
            </Text>
            <Text>
              <Link href="demo">
                DEMO
              </Link>
            </Text>
            <Text>
              <Link href="collection">
                COLLECTION
              </Link>
            </Text>
          </div>
        }
        <div className="header-right">
          <HeaderNav loggedIn={loggedIn} />
        </div>
    </div>
  );
};

export default Header_public;
