import React, {useEffect, useState} from "react";
import {Button, SelectField} from "@aws-amplify/ui-react";
import {API} from "aws-amplify";
import {getUserID} from "../api/api";
import {AddSubjectForm} from "./AddSubjectForm";

type SubjectSelectFieldProps = {
    showAddButton?: boolean;
    selectedSubjectId: string;
    setSelectedSubjectId: (id: string) => void;
};

export const SubjectSelectField: React.FC<SubjectSelectFieldProps> = ({ selectedSubjectId, setSelectedSubjectId, showAddButton = true }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [subjects, setSubjects] = useState<{ id: string, name: string }[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchSubjects();
    }, []);

    const fetchSubjects = async () => {
        setIsLoading(true);
        try {
            const userID = await getUserID();
            const response = await API.get("histopathologyAPI", `/users/${userID}/subjects`, {});
            setSubjects(response);
        } catch (error) {
            console.error("Error fetching subjects:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={{ marginBlockEnd: "2rem" }}>
            <SelectField
                label="Selected subject"
                placeholder="Select a subject"
                value={selectedSubjectId}
                onChange={(e) => setSelectedSubjectId(e.target.value)}
                disabled={isLoading}
            >
                {subjects.map(subject => (
                    <option key={subject.name} value={subject.id}>
                        {subject.name}
                    </option>
                ))}
            </SelectField>
            {showAddButton &&
                <Button style={{ marginTop: "1rem" }} onClick={() => setIsOpen(!isOpen)}>Add New Subject</Button>}

            <AddSubjectForm
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onSubjectAdded={fetchSubjects}
            />
        </div>
    );
};
