// Collection.tsx
import { Flex, Heading } from "@aws-amplify/ui-react";
import WhiteCard from "../../components/WhiteCard";
import "./Collection.css";

export default function Collection() {
  const images = [
    "Heart.png",
    "Fish.png",
    "Horror.png",
    "Heart2.png",
    "SadFace.png",
    "Angel.png"
    // Add more image URLs
  ];
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div>
        <h2>Our Funny Cell Collection</h2>
      </div>
      <WhiteCard>
        <Flex direction="column" alignItems="center">
          <Heading level={3}>Funny Cells</Heading>
          <div className="collection-grid">
            {images.map((image, index) => (
              <img key={index} src={image} alt={`Funny Cell ${index + 1}`} className="collection-image" />
            ))}
          </div>
        </Flex>
      </WhiteCard>
    </div>
  );
}
