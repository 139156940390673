// AlgorithmPopup.tsx
import React from 'react';
import { Heading, Text, Image, Button, Flex, Link } from '@aws-amplify/ui-react';
import './AlgorithmPopup.css';
import { ReactCompareSlider } from 'react-compare-slider';
export interface Algorithm {
  id: number;
  title: string;
  images: string[];
  details: string;
  link: string;
}

interface AlgorithmPopupProps {
  algorithm: Algorithm;
  onClose: () => void;
}

const AlgorithmPopup: React.FC<AlgorithmPopupProps> = ({ algorithm, onClose }) => {

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const formatText = (text: string) => {
    const paragraphs = text.split('\n').map((paragraph, index) => (
      <Text key={index} className="popup-paragraph">
        {paragraph}
      </Text>
    ));
    return <div>{paragraphs}</div>;
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        <Heading level={3} className="popup-title">
          {algorithm.title}
        </Heading>
        <Flex direction="column" alignItems="center">
          {algorithm.images.map((image, index) => (
            <Image
              key={index}
              src={image}
              alt={`${algorithm.title} - Image ${index + 1}`}
              className="popup-image"
            />
          ))}
        </Flex>

        <div className="slider-container">
          <ReactCompareSlider
            itemOne={<img src="/overlay_alpha0.png" alt="Image 1" />}
            itemTwo={<img src="/overlay_alpha09.png" alt="Image 2" />}
          />
        </div>
        <div className="popup-details">{formatText(algorithm.details)}</div>
        <Flex justifyContent="space-between" alignItems="center" className="popup-actions">
          <Link href={algorithm.link} target="_blank" rel="noopener noreferrer" className="popup-link">
            See on arXiv
          </Link>
          <Button variation="primary" onClick={onClose} className="popup-close-button">
            Close
          </Button>
        </Flex>
      </div>
    </div>
  );
};

export default AlgorithmPopup;
