// DeletePopup.tsx
import React from 'react';
import { Heading, Text, Button, Alert, Flex } from '@aws-amplify/ui-react';
import './AlgorithmPopup.css';
import { API, Auth } from 'aws-amplify';
import {useRecoilValue} from "recoil";
import {userIdSelector} from "../recoil/uiState";
interface DeletePopupProps {
  selectedRows: string[];
  subjectId: string | undefined;
  onClose: () => void;
}

const DeletePopup: React.FC<DeletePopupProps> = ({ selectedRows, subjectId, onClose }) => {
  const userId = useRecoilValue(userIdSelector);
  const deleteSlides = async (selectedRows: string[], subjectId: string | undefined) => {
    try {
      const credentials = await Auth.currentCredentials();
      const identityID = credentials.identityId;
      console.log(selectedRows)
      for (const slideId of selectedRows) {
        await API.del('histopathologyAPI', `/users/${userId}/subjects/${subjectId}/slides/${slideId}`, {
          body: {
            SlideId: slideId,
            cognito_identity_id: identityID,
          },
        });
      }
    } catch (error) {
      console.error('Error deleting slides:', error);
      throw error;
    }
  };

  const handleDelete = async () => {
    try {
      await deleteSlides(selectedRows, subjectId);
      onClose();
    } catch (error) {
      console.error('Error deleting slides:', error);
    }
  };

  return (
    <div className="popup-overlay">
      <Alert variation="warning" isDismissible={false}>
        <Heading level={3}>Delete Slides</Heading>
        <Text>Are you sure you want to delete the selected slides?</Text>
        <Flex justifyContent="flex-end">
          <Button variation="link" onClick={onClose}>
            Cancel
          </Button>
          <Button variation="primary" onClick={handleDelete}>
            Delete
          </Button>
        </Flex>
      </Alert>
    </div>
  );
};

export default DeletePopup;
