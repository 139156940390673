import React, { useState } from "react";
import { Alert, Button, Flex, Heading, SelectField, Text } from "@aws-amplify/ui-react";
import { useRecoilValue } from "recoil";
import { userIdSelector } from "../recoil/uiState";
import { isProdEnvironment } from "../utils";
import { submitInference } from "../api/inferenceUtils";
interface InferenceSelectFieldProps {
    subjectId: string;
    slideIds: string[];
}


const inferenceTypes = [
    // {
    //     InferenceType: "SAC",
    //     name: "Sarcoma AI Classification",
    //     info: "Classification of SVS slides on 23 soft-tissue and bone sarcoma",
    //     isRunning: false,
    //     isDisabled: true

    // },
    {
        InferenceType: "MIT",
        name: "Mitoses detection",
        info: "Automatic detection of mitoses in an SVS image",
        isRunning: false,
        isDisabled: true
    },

    // {
    //     InferenceType: "LYM",
    //     name: "Lymphocites detection and classification",
    //     info: "Automatic detection of lymphocites T/B in an SVS image",
    //     isRunning: false,
    //     isDisabled: true
    // },

    // // {
    // //     InferenceType: "TSR",
    // //     name: "Colorectal Tissue Classification (Tumour-Stroma ratio)",
    // //     info: "Automatic detection of colorectal tissue type in an SVS image",
    // //     isRunning: false,
    // //     isDisabled: true
    // // }
];

export const InferenceSelectField: React.FC<InferenceSelectFieldProps> = ({ subjectId, slideIds }) => {
    const [selectedInferenceId, setSelectedInferenceId] = useState<string>("");
    const [inferences, setInferences] = useState<{ InferenceType: string, name: string, info: string, isRunning: boolean, }[]>(inferenceTypes);
    const userId = useRecoilValue(userIdSelector);
    const [modelVersion, setModelVersion] = useState('stable'); // 'stable' or 'dev'
    const [isJobRunning, setIsJobRunning] = useState(false);

    const selectedInference = inferences.find(inference => inference.InferenceType === selectedInferenceId);

    const handleInference = async () => {
        if (selectedInference) {
          const responses = await submitInference(
            userId,
            subjectId,
            slideIds,
            selectedInference,
            modelVersion
          );
          console.log("Responses:", responses);
        }
      };
      
    const allParamsValid = slideIds !== undefined && subjectId !== undefined;


    return (
        <div style={{ marginBlockEnd: "2rem" }}>

            <Heading color="#333" textAlign="center">Compute a new Inference</Heading>
            <br />
            <SelectField
                label="AI Inference model available:"
                placeholder="Select an inference type"
                value={selectedInferenceId}
                onChange={(e) => setSelectedInferenceId(e.target.value)}
            >
                {inferences.map(inference => (
                    <option key={inference.InferenceType} 
                    value={inference.InferenceType}>
                        {inference.name}
                    </option>
                ))}
            </SelectField>

            {selectedInference && <Alert
                isDismissible={false}
                hasIcon={false}
                heading="Inference Info"
                style={{ marginBlock: "2rem" }}
            >
                <Flex direction="column" gap="small">
                    <Text>Inference Name: {selectedInference.name}</Text>
                    <Text>{selectedInference.info}</Text>
                    <Flex direction="column" justifyContent="flex-start" alignContent="center">

                        {!isJobRunning && !isProdEnvironment() &&
                            <SelectField
                                label="Model version:"
                                value={modelVersion}
                                onChange={(e) => setModelVersion(e.target.value)}
                            >
                                <option key="stable" value="stable">Stable</option>
                                <option key="dev" value="dev">Dev</option>
                            </SelectField>}
                        {!isJobRunning && <Button
                            onClick={handleInference}
                            variation="primary"
                            disabled={isJobRunning}>Launch AI Inference</Button>}

                    </Flex>

                </Flex>

            </Alert>
            }
        </div >
    );
};
