import React from "react";
import {Icon} from "@aws-amplify/ui-react";

import {MdDashboard, MdOutlineTableChart, MdUpload} from "react-icons/md";
import {BsFillPeopleFill} from "react-icons/bs";

export const baseConfig = {
    titleSuffix: "",
    search: true,
    header: true,
    headerText: "Octopath",
    footer: true,
    footerText: (
        <>
            <span>
                © OctoPath {new Date().getFullYear()} - Collins-Fekete Lab
            </span>
        </>
    ),

    logo: (
        <>
            <img
                src={"/OctopathLogo.svg"} 
                alt="logo "
                width="50"
                height="50"
            />
        </>
    ),
};

/// Navigation sidebar
export const appNavs = [
    {
        eventKey: "dashboard",
        icon: <Icon as={MdDashboard} />,
        title: "Dashboard",
        to: "/dashboard",
    },
    {
        eventKey: "subject",
        icon: <Icon as={BsFillPeopleFill} />,
        title: "Subjects",
        to: "/subjects",
    },    
    {
        eventKey: "uploadSlide",
        icon: <Icon as={MdUpload} />,
        title: "Upload Slides",
        to: "upload"
    },
    {
        eventKey: "slides",
        icon: <Icon as={MdOutlineTableChart} />,
        title: "View Slides",
        to: "/slides",
    },


];
