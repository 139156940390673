import React from "react";
import ReactDOM from "react-dom/client";
import "@aws-amplify/ui-react/styles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import awsConfig from "./auth/aws-exports";
import {Amplify} from "aws-amplify";
import {BrowserRouter} from "react-router-dom";


Amplify.configure(awsConfig);

const rootElement = document.getElementById("root");
if (!rootElement) {
    throw new Error("Failed to find the root element");
}

const root = ReactDOM.createRoot(rootElement);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App isPassedToWithAuthenticator={false} />
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
