// TableHeader.tsx
import { Menu, MenuButton, MenuItem, Alert, AlertVariations, Heading } from "@aws-amplify/ui-react";
import { ReactComponent as NeuralNetworkIcon } from '../../neural-network-icon.svg';
import { MdFileDownload, MdDelete } from 'react-icons/md';
import { useState } from "react";
import { submitInference, downloadAIResults } from "../../api/inferenceUtils";
import DeletePopup from '../../components/DeletePopup';
import { TableHeaderProps } from "./types";
const TableHeader = ({
    selectedRows,
    userId,
    subjectId,
    fetchSlides,
    setSelectAll,
    setSelectedRows
}: TableHeaderProps) => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const handleSubmitInference = async (inferenceType: string) => {
        try {
            await submitInference(userId, subjectId, selectedRows, { InferenceType: inferenceType }, "stable");
            setAlertMessage(`Inference ${inferenceType} submitted for Slide Ids ${selectedRows.join(", ")}`);
            setAlertType('success');
            setShowAlert(true);
        } catch (error) {
            setAlertMessage('Error submitting inference');
            setAlertType('error');
            setShowAlert(true);
        }
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>

                <div style={{ flex: 1 }}>
                    <Menu menuAlign="start" trigger={
                        <MenuButton variation="menu" disabled={selectedRows.length === 0} >
                            <div className="avatar">
                                <NeuralNetworkIcon width={"1em"} height={"1em"} fill={'currentcolor'} strokeWidth={0} />
                            </div>
                        </MenuButton>
                    }>
                        <MenuItem isDisabled={true} onClick={() => submitInference(userId, subjectId, selectedRows, { InferenceType: "SAC" }, "stable")}>
                            Sarcoma Classification
                        </MenuItem>
                        <MenuItem onClick={() => handleSubmitInference("MIT")}>
                            Mitoses
                        </MenuItem>
                        <MenuItem isDisabled={true} onClick={() => { submitInference(userId, subjectId, selectedRows, { InferenceType: "LYM" }, "stable") }}>
                            Lymphocytes and Macrophages
                        </MenuItem>
                    </Menu>
                    <Menu menuAlign="start" trigger={
                        <MenuButton variation="menu" disabled={selectedRows.length === 0} >
                            <div className="avatar">
                                <MdFileDownload />
                            </div>
                        </MenuButton>
                    }>
                        <MenuItem onClick={() => { downloadAIResults(userId, subjectId, selectedRows) }}>Download AI results</MenuItem>
                    </Menu>
                    <Menu isDisabled={true} menuAlign="start" trigger={
                        <MenuButton variation="menu" disabled={selectedRows.length === 0} >
                            <div className="avatar">
                                <MdDelete />
                            </div>
                        </MenuButton>
                    }>
                        <MenuItem onClick={() => setIsDeleteConfirmationOpen(true)}>
                            Delete slides
                        </MenuItem>
                    </Menu>
                    {isDeleteConfirmationOpen && (
                        <DeletePopup
                            selectedRows={selectedRows}
                            subjectId={subjectId}
                            onClose={() => {
                                setSelectedRows([]);
                                setSelectAll(false);
                                setIsDeleteConfirmationOpen(false);
                                fetchSlides();
                            }}
                        />
                    )}
                </div>
                <Heading color="#333" textAlign="center">Slides</Heading>
                <div style={{ flex: 1 }}></div>

            </div>
            {showAlert && (
                <Alert
                    variation={alertType as AlertVariations}
                    isDismissible={true}
                    hasIcon={true}
                    heading=""
                    onDismiss={() => setShowAlert(false)}
                >
                    {alertMessage}
                </Alert>
            )}
        </>
    );
};

export default TableHeader;
