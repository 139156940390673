import {Flex, View} from "@aws-amplify/ui-react";
import {generateThumbnail, sanitizeFileNameWithSubjectID} from "../api/api";
import {StorageManager} from "@aws-amplify/ui-react-storage";

export const UploadSlideForm = ({ selectedSubjectId }: { selectedSubjectId: string }) => {    
    return (
        <Flex direction="column " gap="1rem">
            <View padding="1rem" border="1px solid grey">
                <div>
                    <StorageManager
                        acceptedFileTypes={['.svs, .zip, .ndpi','.scn','.tif','.dcm', '.czi']}
                        accessLevel="protected"
                        maxFileCount={100}
                        isResumable
                        processFile={({ key, file }) => sanitizeFileNameWithSubjectID({ file, selectedSubjectId })}
                        displayText={{
                            dropFilesText: 'Drag an .svs, .mrsx(zip), .ndpi, .tif, .czi or .dcm file here',
                        }}
                        onUploadSuccess={({ key }) => {
                            if (typeof key === 'string') {
                                generateThumbnail(key);
                            }
                        }}
                    />
                </div>
            </View>
        </Flex>
    );
};

export default UploadSlideForm;
