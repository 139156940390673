import SubjectsTable from "../../feature/SubjectsTable";
import WhiteCard from "../../components/WhiteCard";

export default function Subjects() {

    return (
        <>
            <div>
                <h2>Subjects</h2>
            </div>
            <WhiteCard>
                <SubjectsTable />
            </WhiteCard>
        </>

    );

}
